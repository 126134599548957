.avatar {
  display: inline-block;
  width: 12rem;
  height: 12rem;
  position: relative;
  text-align: center;
}

.avatar__image {
  height: 100%;
  width: auto;
}

/* Content with UBB */
/* <q> */
.bbq {
  background-color: #dae5f3;
}
/* <img> */
.ubb__img {
  display: inline-block;
  margin: 0;
  max-width: 100%;
  height: auto;
  border: 0;
}
/* blue baozi gif */
.bb {
  vertical-align: text-bottom;
  display: inline-block;
}

/* logged-in hint */
.loggedin {
  background-image: url('/assets/_sn_/verified-24px.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - var(--gap)) 50%;
}

/* ajax spinner */
@keyframes spinner-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dual-ring {
  display: none;
  width: 32px;
  height: 32px;
}
.dual-ring::after {
  content: ' ';
  display: block;
  width: 24px;
  height: 24px;
  border-width: 4px;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--color-complement-0) transparent var(--color-complement-0) transparent;
  animation: spinner-dual-ring 1.2s linear infinite;
}
.dual-ring.show {
  display: inline-block;
}

/* HTML Dialog element */
dialog::backdrop {
  background-color: rgba(0 0 0 / 25%);
}
.dlg {
  border: 0;
  padding: 0;
  box-shadow: 0 0 var(--gap) var(--gap05) rgba(0 0 0 / 25%);
  width: 80vw;
  max-width: 60rem;
}
.dlg__header {
  background-color: var(--color-primary-4);
  display: flex;
  align-items: center;
  gap: var(--gap);
}
.dlg__title {
  margin: 0;
  font-size: var(--base-font-size);
  line-height: calc(var(--base-font-size) + 2rem);
  color: #fff;
  flex: 1;
  padding: 0 0 0 var(--gap);
}
.dlg__close {
  --close-size: calc(var(--base-font-size) + 1rem);

  width: var(--close-size);
  height: var(--close-size);
  padding: var(--gap);
}
.dlg__closebtn {
  border: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.dlg__closebtn > span {
  color: #fff;
  background-color: var(--color-named-red);
  font-size: var(--close-size);
  line-height: var(--close-size);
  font-weight: bold;
  display: block;
  height: var(--close-size);
  border-radius: 100%;
}
.dlg__body {
  padding: var(--gap);
}
