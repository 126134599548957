.snackbar {
  position: fixed;
  z-index: 99;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.4s;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
.snackbar.expand {
  width: 100vw;
  height: auto;
  top: 75%;
}
.snackbar.show {
  opacity: 1;
}
.snackbar__prompt {
  padding: var(--gap);
  background-color: rgb(0 0 0 / 60%);
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 60%);
  color: #fff;
}
