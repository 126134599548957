/* Overwrites border0 */
html {
  font-size: 10px; /* 1rem = 10px */
}

body {
  background: #ededed;
  font-family: var(--font-family-sans);
  margin: 0;
  padding: 0;
}
.mgmt {
  background: #d2d2d2;
}

pre > code {
  font-family: var(--font-family-mono);
}

a {
  text-decoration: none;
}

p:empty {
  margin: 0;
}

#main {
  margin: var(--header-height-mobile) auto 0;
  padding: var(--gap);
}

/* Tablet */
@media (min-width: 44em) {
  #main {
    margin: var(--header-height-tablet) auto 0;
    max-width: 86rem;
    padding: var(--gap2);
  }
}

/* Desktop */
@media (min-width: 76em) {
  #main {
    margin: 0 0 0 var(--header-width-desktop);
  }
}
