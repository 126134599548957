.header {
  background-color: var(--color-primary-0);
  color: #fff;
  padding: 0 var(--gap) 0 0;
  height: var(--header-height-mobile);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: fixed; /* position context for nav-menus */
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-image: var(--header-bg-url);
  background-size: cover;
}
.header__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: var(--header-height-mobile);
  box-sizing: border-box;
  padding: 0 var(--gap);
  color: #fff;
  border: 0;
  background-color: transparent;
  outline: 0;
}
.header__title {
  font-size: 2rem;
  line-height: 3.2rem;
  margin: 0;
  padding: 0;
  height: 3.2rem; /* no more line-break */
  overflow: hidden;
  flex: 1;
}
.header__tagline {
  display: none;
}
.header__span {
  display: none;
}
.header__avatar {
  width: calc(var(--header-height-mobile) - var(--gap2));
  height: calc(var(--header-height-mobile) - var(--gap2));
  border-radius: 50%;
  border: 2px solid #fff;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  background-color: #fff;
}
.header__avatar img {
  width: 100%;
  height: auto;
  border: 0;
}

/* [popover] */
.header__nav {
  position: fixed;
  top: var(--header-height-mobile);
  left: 0;
  width: 100%;
  background-color: var(--color-named-lightgray);
  z-index: 5;
  padding: 0;
  margin: 0;
  border: 0;
  border-bottom: 4px solid var(--color-complement-2);
}

::backdrop {
  background-color: rgb(0 0 0 / 50%);
}

.header__navlist {
  padding: 0;
  margin: 0;
}
.header__navlist_user {
  background-color: #e3e3e3; /* tried */
}
.header__navitem {
  list-style: none;
  margin: 0;
  padding: 0;
}
.header__navitem a {
  display: flex;
  align-items: center;
  gap: var(--gap05);
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: var(--color-complement-4);
  padding: var(--gap);
  box-sizing: border-box;
}
.header__navitem a > i {
  width: 2.4rem;
}
.header__navitem a > span {
  display: inline-block;
  flex: 1;
}

.header__navitem_user a {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-primary-4);
}

@media (min-width: 44em) {
  .header {
    height: var(--header-height-tablet);
    padding: 0 var(--gap2) 0 0;
  }
  .header__trigger {
    width: 6.4rem;
    height: var(--header-height-tablet);
  }
  .header__title {
    font-size: 2.4rem;
    line-height: 3.6rem;
    height: 3.6rem; /* no more line-break */
  }
  .header__avatar {
    width: calc(var(--header-height-tablet) - var(--gap2));
    height: calc(var(--header-height-tablet) - var(--gap2));
  }

  .header__nav {
    top: var(--header-height-tablet);
    border-bottom-width: 8px;
    width: 50%;
  }
  .header__navlist {
    display: flex;
    flex-wrap: wrap;
  }
  .header__navitem {
    width: 50%;
  }
  .header__navitem a {
    padding: var(--gap) var(--gap) var(--gap) var(--gap2);
    gap: var(--gap);
  }
}

@media (min-width: 76em) {
  .header {
    width: var(--header-width-desktop);
    height: auto;
    right: auto;
    bottom: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
  .header__trigger {
    width: 100%;
    height: 4.4rem;
    padding: 0 0 0 var(--gap);
    align-items: center;
    justify-content: flex-start;
  }
  .header__title {
    font-size: 1.6rem;
    line-height: 2.6rem;
    height: auto;
    overflow: auto;
    margin: var(--gap) 0 var(--gap2);
    padding: 0 var(--gap);
    flex: 0 1 auto; /* overwrite */
  }
  .header__tagline {
    display: block;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--color-primary-1);
    margin: 0;
    padding: 0 var(--gap);
  }
  .header__span {
    display: block;
    flex: 1;
  }
  .header__avatar {
    width: 10.4rem;
    height: 10.4rem;
    border: 0.4rem solid #fff;
    margin: 0 0 var(--gap) var(--gap);
  }
  .header__avatar img {
    width: 100%;
    height: auto;
    border: 0;
  }
  .header__nav {
    top: 4.4rem;
    width: var(--header-width-desktop);
  }
  .header__navlist {
    display: block;
  }
  .header__navitem {
    width: auto;
  }
  .header__navitem a {
    padding: var(--gap);
  }
  .header__navitem a:hover,
  .header__navitem_user a:hover {
    color: #fff;
    background-color: var(--color-complement-4);
  }
}
