.tabs__list {
  display: flex;
  gap: var(--gap05);
  margin: 0;
}
.tabs__item {
  margin: 0;
  list-style: none;
}
.tabs__item > a {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: var(--color-primary-3);
  padding: var(--gap05);
  gap: var(--gap05);
}
.tabs__item_current > a {
  color: var(--color-primary-3);
  background-color: #fff;
}
.tabs__item_spec > a {
  color: var(--color-primary-3);
  background-color: #d2d2d2; /* same as bg */
}
.tabs__item i {
  width: 2.4rem; /* icon size, prevent CLS */
}
.tabs__item_spec form {
  display: none; /* logout form */
}
.tabs__text {
  display: none;
}
.tabs__badge {
  display: inline-block;
  min-width: 0.4rem;
  height: 1.4rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.4rem;
  background-color: var(--color-primary-1);
  color: #fff;
  border-radius: 0.8rem;
  margin: 0 0 0 var(--gap05);
  padding: 0.1rem var(--gap05);
}
.tabs__badge_new {
  background-color: darkorange;
  color: #fff;
  font-weight: bold;
}
/* on page (out of this module) */
.tabs__body {
  padding: var(--gap);
  background-color: #fff;
}
/* Sub-tab navi (outside this module) */
.subtabs {
  margin: 0 0 var(--gap);
  display: flex;
  gap: var(--gap05);
}
.subtab {
  list-style: none;
  margin: 0;
  line-height: calc(var(--base-font-size) + 1rem);
}
.subtab a {
  display: flex;
  background-color: #fff;
  color: var(--color-primary-2);
  padding: var(--gap05);
  align-items: center;
  gap: var(--gap05);
}
.subtab_active a {
  background-color: var(--color-primary-3);
  color: #fff;
}

@media (min-width: 44em) {
  .tabs__list {
    gap: var(--gap);
  }
  .tabs__item > a {
    padding: var(--gap);
  }
  .tabs__text {
    font-size: 1.4rem;
    line-height: 2.4rem;
    display: inline-block;
  }
  .tabs__badge {
    margin: 0 var(--gap05);
  }
  .tabs__body {
    padding: var(--gap2);
  }
  .subtabs {
    gap: var(--gap);
  }
}

@media (min-width: 76em) {
  .tabs__text {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}
