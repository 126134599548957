/* Web fonts, generated from https://gwfh.mranftl.com/fonts */

/* assetsUrlPath = '/assets/_sn_' */

/* Source Sans 3 Regular */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/_sn_/wf/source-sans-3-v15-latin-regular.woff2') format('woff2');
}

/* Source Sans 3 Bold */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/assets/_sn_/wf/source-sans-3-v15-latin-700.woff2') format('woff2');
}

/* Roboto Mono Regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/_sn_/wf/roboto-mono-v23-latin-regular.woff2') format('woff2');
}

/* Icon fonts */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/_sn_/wf/material-icons.woff2') format('woff2');
}

/* stylelint-disable */
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}
