.footer {
  background: var(--color-primary-0) url('/assets/_sn_/512bluewhite.darkbg.webp') no-repeat var(--gap) 50%;
  background-size: 5.2rem;
  padding: var(--gap);
}
.footer__text {
  text-align: right;
}
.footer__text p {
  color: var(--color-primary-1);
  /* .fslh(1.4rem); */
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin: 0;
}
.footer__text em {
  color: #fff;
  font-style: normal;
}

@media (min-width: 44em) {
  .footer {
    background-position: var(--gap2) 50%;
    background-size: 6rem;
    padding: var(--gap2);
  }
  .footer__text p {
    /* .fslh(1.6rem); */
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@media (min-width: 76em) {
  .footer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    width: 2rem; /* visual width 60px */
    background-size: 4.8rem;
    background-position: 50% var(--gap);
  }
  .footer__text {
    position: absolute;
    bottom: 14rem; /* tried out */
    right: -12rem; /* width(300) - footer witdh(60) / 2 */
    height: 4.8rem; /* two lines */
    width: 30rem; /* random... */
    transform: rotate(90deg);
  }
  .footer__text p {
    /* .fslh(1.4rem); */
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}
