:root {
  /* Colors */
  --color-demo-bg: #e5e5e5;
  --color-demo-primary: #4682b4;
  --color-named-white: #fff;
  --color-named-green: #5c8427;
  --color-named-red: #c10;
  --color-named-lightgray: #f8f8f8;

  /* Fonts */
  --font-family-sans: 'Source Sans 3', sans-serif;
  --font-family-mono: 'Roboto Mono', monospace;

  /* Break points */
  --bp-tablet: 44em; /* 704px (tablet portrait) < 768px */
  --bp-desktop: 76em; /* 1216px */

  /* Distance */
  --gap05: 0.5rem;
  --gap: 1rem;
  --gap2: 2rem;
  --gap4: 4rem;
  --header-height-mobile: 6.4rem;
  --header-height-tablet: 8rem;
  --header-width-desktop: 30rem;
}
