.entry {
  padding: var(--gap);
  margin: 0 0 var(--gap);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 3.6rem;
}
.entry:last-child {
  margin-bottom: 0;
}
.entry a:link,
.entry a:visited,
.entry a:active {
  color: var(--color-complement-4);
}
.entry a:hover {
  color: #fff;
  background-color: var(--color-complement-4);
}

.entry__title {
  font-size: 2rem;
  line-height: calc(2rem + 1.4rem);
}

.entry__footer {
  padding: var(--gap) 0 0;
}

.entry__tags {
  display: flex;
  align-items: center;
  gap: var(--gap05);
}

.entry__tag {
  list-style: none;
  padding: 0;
  margin: 0;
}
.entry__tag::before {
  content: '#';
  color: lightgray;
}

.entry__tag_icon {
  line-height: 0;
}
.entry__tag_icon::before {
  display: none;
}

.entry__misc {
  display: flex;
  align-items: center;
  gap: var(--gap05);
}

.entry__category {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}

.entry__comment a {
  display: flex;
  align-items: center;
  gap: var(--gap05);
}

.entry__edit {
  margin: 0 var(--gap05) 0 0;
  line-height: 0;
}
.entry__edit a {
  background-color: var(--color-complement-1);
  display: inline-block;
}

.entry__content {
  overflow-x: hidden;
}

.entry__contentimg {
  display: inline-block;
  margin: 0;
  max-width: 100%;
  height: auto;
  border: 0;
}

.entry__contentmasked {
  color: darkgray;
}

/* entry types */
.entry_internal {
  background-image: url('/assets/_sn_/public_off-24px.svg');
}

.entry_secret {
  background-image: url('/assets/_sn_/lock-24px.svg');
}

/* read more */
.learnmore {
  background-color: #fff;
  padding: var(--gap);
  display: flex;
  align-items: center;
  gap: var(--gap05);
}
.learnmore p {
  margin: 0;
}
.learnmore a {
  color: var(--color-complement-4);
}
.learnmore a:hover {
  color: #fff;
  background-color: var(--color-complement-4);
}

@media (min-width: 44em) {
  .entry {
    padding: var(--gap2);
    margin: 0 0 var(--gap2);
    background-size: 6rem;
  }
  .entry__title {
    font-size: 2.2rem;
    line-height: calc(2.2rem + 1.4rem);
  }
  .entry__tags {
    gap: var(--gap);
  }
  .entry__misc {
    gap: var(--gap);
  }
  .entry__edit {
    margin: 0 var(--gap) 0 0;
  }

  .learnmore {
    gap: var(--gap);
    padding: var(--gap2);
  }
}

@media (min-width: 76em) {
  .entry__title {
    font-size: 2.4rem;
    line-height: calc(2.4rem + 1.4rem);
  }
}
